import styled, { device } from "@theme/utils";

export const Section = styled.div`
    margin-top: 30px;
`;
export const Title = styled.h3``;
export const DescriptionText = styled.p`
    color: #000;
    margin-top: -5px;
    text-align: justify;
    line-height: 130%;
    margin-right: 35px;
    @media only screen and (max-width: 950px) {
        margin-right: auto;
    }
`;
