import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../../shared/button";
// import Follow from "../follow";
import { Banner, Name, DescriptionText } from "./style";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProfileBanner = ({ image, name, designation }) => {
    return (
        <Banner>
            <Link to='/' className='logo'>
                {/* <GatsbyImage image={getImage("../../data/images/logo.svg")} alt={name} /> */}
                {/* <StaticImage
                    src='https://reliance-cdn.dev.quanticdynamics.cloud/directors/Chairman.png'
                    alt='Chairman'
                /> */}
                <img src={image} alt={name} />
            </Link>
            <Name>{name}</Name>
            <DescriptionText>{designation}</DescriptionText>
            {/* <Follow /> */}
            {/* <Button path='/' size='medium' shape='rounded5'>
                Contact Us <i className='icofont-rounded-double-right'></i>
            </Button> */}
        </Banner>
    );
};

export default ProfileBanner;
