import { Section, Title, DescriptionText } from "./style.jsx";
const SectionArea = ({
    title,
    disc1,
    disc2,
    disc3,
    disc4,
    disc5,
    disc6,
    disc7,
}) => {
    return (
        <Section>
            <Title>{title}</Title>
            <DescriptionText>{disc1}</DescriptionText>
            <DescriptionText>{disc2}</DescriptionText>
            <DescriptionText>{disc3}</DescriptionText>
            <DescriptionText>{disc4}</DescriptionText>
            <DescriptionText>{disc5}</DescriptionText>
            <DescriptionText>{disc6}</DescriptionText>
            <DescriptionText>{disc7}</DescriptionText>
        </Section>
    );
};

export default SectionArea;
