import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import Profile from "../../components/Profile";
import { normalizedData } from "@utils/functions";
import jsonDataInit from "../../data/about/boardofdirectors/jsondata.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const BoardOfDirectors = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a6098609da64d3c28fa5dd"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Board Of Directors" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Board Of Directors"
            />
            {jsonData.map((d, j) => (
                <Profile
                    key={j}
                    image={d.image}
                    name={d.title1}
                    designation={d.title2}
                    title={d.title}
                    disc1={d.text}
                    disc2={d.text1}
                    disc3={d.text2}
                />
            ))}
            {/* <h3 style={{ textAlign: "center" }}>Director's Profile</h3> */}
        </Layout>
    );
};
BoardOfDirectors.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query BoardOfDirectorsQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default BoardOfDirectors;
